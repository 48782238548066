import BasePlugin from '../BasePlugin'

export default class RecalculationOfDeltasInTheAdjustmentRequestCommand extends BasePlugin {
  async execute () {
    let adjustmentRequestId = this.context.getModel().id
    this.context.getCard().setLoading(true)

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/RecalculateDeltasOfAdjustmentRequestCommand`,
      {
        'adjustmentRequestId': adjustmentRequestId,
        'async': 'true'
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).catch((error) => {
      switch (error.response.data.code) {
        case 'investment_expenses_budget_not_found':
          this.context.$message.error('БИРы по данной заявке на корректировку не найдены')
          break
        default:
          this.context.$message.error('Неизвестная ошибка')
          break
      }
    }).then((response) => {
      this.context.getCard().setLoading(false)
      this.context.$msgbox({
        type: 'info',
        message: 'Расчет дельт поставлен в очередь. По завершении вы получите уведомление'
      })
    })
  }
}
